import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from '../../common/components/Card';

const Wrapper = styled.div`
  color: initial;
`;

const FinanceReports = () => {
  return (
    <Card floating padded={false} bodyBackground="none" title="Reports">
      <div className="flex flex-wrap">
        <Link className="w-1/3 p-2" to="/finance/reports/weekly-snapshot">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Weekly Timesheet Snapshot</h2>
              <p className="text-gray-600">
                Show weekly tasks and hours for all employees for a given range.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/daily-snapshot">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Daily Timesheet Snapshot</h2>
              <p className="text-gray-600">
                Show daily tasks and hours for all employees for a given range.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/payroll-allocation">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Payroll Allocation</h2>
              <p className="text-gray-600">
                Generate employee allocation report for payroll submission.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/timeoff">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Time Off Report</h2>
              <p className="text-gray-600">
                Generate employee time off report.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/quickbooks-export">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Quickbooks Export</h2>
              <p className="text-gray-600">
                Export timesheet data for the purposes of importing into Quickbooks desktop edition.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/projects">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Projects</h2>
              <p className="text-gray-600">
                Show all projects and relevant details.
              </p>
            </Wrapper>
          </Card>
        </Link>
      </div>
    </Card>
  );
};

export default FinanceReports;
