import gql from 'graphql-tag';
import { Timesheet } from '../timesheets/queries';

export const GET_DELIVERABLES_BY_PROJECT_ID = gql`
  query getProjectDeliverablesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        budget
        deliverables(withHours: true) {
          _id
          projectId
          milestoneName
          deliverableName
          accountingCode
          accountingCodeSuffix
          totalApprovedHours
          totalCost
          budget
          budgetPercent
          rate
          includeInAllProjects
        }
        expenses {
          name
          amount
        }
      }
    }
  }
`;
export const GET_RESOURCES_BY_PROJECT_ID = gql`
  query getProjectResourcesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        resources {
          _id
          name
          email
          role
          startDate
          endDate
          allowProjectManagement
          timesheetApprover
          user {
            _id
            imageUrl
            title
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const GET_TIMESHEETS_BY_PROJECT_ID = gql`
  query getProjectTimesheetsByProjectId(
    $projectId: String
    $startDate: String
    $endDate: String
    $showNotSubmitted: Boolean
  ) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        timesheets(startDate: $startDate, endDate: $endDate, showNotSubmitted: $showNotSubmitted) {
          _id
          lineItems {
            projectId
            totalHours
          }
          approvalsRequired {
            status
            approvers {
              firstName
            }
            approvedOn
            approvedBy
            canApprove
          }
          resource(projectId: $projectId) {
            role
            email
          }
          user {
            _id
            firstName
            lastName
            imageUrl
            title
            role
          }
          status
          totalHours
          endDate
          documentUrl
        }
      }
    }
  }
`;
export const GET_TIMESHEET_SNAPSHOT_BY_PROJECT_ID = gql`
  query getProjectTimesheetSnapshotByProjectId(
    $projectId: String
    $startDate: String
    $endDate: String
    $showNotSubmitted: Boolean
  ) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        roles {
          _id
          name
          rate
        }
        resources {
          _id
          email
          role
          startDate
          endDate
        }
        timesheetSnapshots(
          startDate: $startDate
          endDate: $endDate
          showNotSubmitted: $showNotSubmitted
        )
      }
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query getProjectById($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        accountingCode
        accountingCodeSuffix
        projectCode
        name
        owner
        description
        archived
        sectors
        budget
        budgetUsed
        proposedBudget
        deductExpenses
        client {
          _id
          shortName
          name
        }
        endClient {
          _id
          shortName
          name
        }
        roles {
          _id
          name
        }
        quickbooks {
          jobName
        }
      }
    }
  }
`;

export const GET_TIMESHEET_BY_ID = gql`
  query getTimesheetById($timesheetId: String) {
    projectManagement {
      timesheet(timesheetId: $timesheetId) {
        _id
        status
        totalHours
        startDate
        endDate
        isLocked
        timeline {
          date
          createdBy
          metadata
          type
        }
        lineItems {
          project {
            _id
            name
            client {
              _id
              shortName
            }
          }
          deliverable {
            _id
            deliverableName
            milestoneName
          }
          comment
          monTask {
            ...Weekday
          }
          tueTask {
            ...Weekday
          }
          wedTask {
            ...Weekday
          }
          thuTask {
            ...Weekday
          }
          friTask {
            ...Weekday
          }
          satTask {
            ...Weekday
          }
          sunTask {
            ...Weekday
          }
        }
      }
    }
  }
  ${Timesheet.fragments.weekday}
`;

export const GET_TIMESHEETS_FOR_APPROVAL = gql`
  query getTimesheetsForApproval($startDate: String, $endDate: String, $projectId: String) {
    projectManagement {
      timesheets(startDate: $startDate, endDate: $endDate, projectId: $projectId) {
        _id
        approvalsRequired {
          status
          approvedOn
          approvedBy
          canApprove
          approvers {
            firstName
            lastName
            imageUrl
            emails {
              address
            }
          }
        }
        timesheetAttachments {
          _id
          url
          description
        }
        timesheetExpense {
          description
          amount
          attachments {
            _id
            url
          }
        }
        user {
          _id
          firstName
          lastName
          title
          imageUrl
        }
        resource {
          role
          email
        }
        status
        totalHours
        endDate
        userId
      }
    }
  }
`;

export const GET_APPROVED_TIMESHEETS = gql`
  query getApprovedTimesheets($endDate: String!) {
    projectManagement {
      approvedTimesheets(endDate: $endDate) {
        _id
        endDate
        timesheetAttachments {
          _id
          url
          description
        }
        timesheetExpense {
          description
          amount
          attachments {
            _id
            url
          }
        }
        approvalsRequired {
          status
          approvedOn
          approvedBy
          canApprove
          approvers {
            firstName
            lastName
            imageUrl
            emails {
              address
            }
          }
        }
        user {
          _id
          imageUrl
          firstName
          lastName
        }
        resource {
          role
          email
        }
        status
        totalHours
        submittedDate
        approvedDate
      }
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  fragment project on Project {
    _id
    accountingCode
    projectCode
    name
    description
    archived
    budget
    budgetUsed
    resources {
      _id
      user {
        _id
        firstName
        lastName
        imageUrl
        emails {
          address
        }
      }
    }
    client {
      _id
      name
      shortName
    }
  }
  query getAllProjects($offset: Int, $limit: Int, $search: String, $budgetUsed: Float, $sortBy: String, $sortOrder: Int, $client: String) {
    projectManagement {
      allProjects(offset: $offset, limit: $limit, search: $search, budgetUsed: $budgetUsed, sortBy: $sortBy, sortOrder: $sortOrder, client: $client) {
        ...project
      }
    }
  }
`;

export const GET_MY_PROJECTS = gql`
  fragment project on Project {
    _id
    accountingCode
    projectCode
    name
    description
    archived
    budget
    budgetUsed
    client {
      _id
      name
      shortName
    }
  }
  query getMyProjects {
    projectManagement {
      myProjects {
        ...project
      }
    }
  }
`;
