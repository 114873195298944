import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import numeral from 'numeral';
import { Empty, Alert, Tooltip as AntTooltip } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Label,
  Sector,
  BarChart,
  Bar,
  XAxis,
  ReferenceLine,
  Cell,
} from 'recharts';
import gql from 'graphql-tag';
import Card from '../../common/components/Card';
import TextLoader from '../../common/components/TextLoader';
import { colors } from '../../common/theme';
import ProjectFinancialsDetailsTable from './ProjectFinancialsDetailsTable';

const GET_DELIVERABLES_AND_EXPENSES = gql`
  query getProjectDeliverablesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        deductExpenses
        deliverables(withHours: true) {
          _id
          projectId
          milestoneName
          deliverableName
          accountingCode
          accountingCodeSuffix
          totalApprovedHours
          totalCost
          budget
          includeInAllProjects
          tasks {
            _id
            hours
          }
        }
        expenses {
          _id
          name
          amount
        }
      }
    }
  }
`;

export const calcBudgetFill = ({ budgetUsedPct }) => {
  if (budgetUsedPct > 0.8) {
    return colors.red;
  }
  if (budgetUsedPct > 0.6) {
    return colors.orange;
  }
  return colors.green;
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    centerLabel,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 50) * cos;
  const my = cy + (outerRadius + 50) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={25} textAnchor="middle" fill="#999">
        {centerLabel}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`${numeral(percent).format('0,0.0%')}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${payload.name}`}
      </text>
    </g>
  );
};

const Table = ({ children, className, ...rest }) => {
  return (
    <table className={classNames('w-full', className)} {...rest}>
      {children}
    </table>
  );
};
const Thead = ({ children, className, ...rest }) => {
  return (
    <thead
      className={classNames(
        'border border-t-0 border-x-0 border-b border-solid border-gray-500 text-center',
        className,
      )}
      {...rest}
    >
      {children}
    </thead>
  );
};
const Tbody = ({ children, className, ...rest }) => {
  return (
    <tbody className={classNames('text-center', className)} {...rest}>
      {children}
    </tbody>
  );
};
const Tr = ({ children, className, ...rest }) => {
  return (
    <tr className={classNames('', className)} {...rest}>
      {children}
    </tr>
  );
};
const Th = ({ children, className, ...rest }) => {
  return (
    <th
      className={classNames('py-2', className)}
      style={{
        maxWidth: '125px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      {...rest}
    >
      {children}
    </th>
  );
};
const Td = ({ children, className, ...rest }) => {
  return (
    <td
      className={classNames('py-2', className)}
      style={{
        maxWidth: '125px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      {...rest}
    >
      {children}
    </td>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    if (payload[0].name === 'Variance') {
      const variance = payload[0].value;
      return (
        <div className="bg-gray-100 p-2">
          <p className="">{`${label}`}</p>
          <p className="">Budget Remaining: {variance > 0 ? variance : 0}</p>
          <p className="">Over Budget: {variance < 0 ? variance * -1 : 0}</p>
        </div>
      );
    }

    const totalCost = payload[0]?.value || 0;
    const budgetRemaining = payload[1]?.value || 0;
    return (
      <div className="bg-gray-100 p-2">
        <p className="font-bold">{`${label}`}</p>
        <p className="">Total Cost: {numeral(totalCost).format('$0,0')}</p>
        <p className="">
          Budget Remaining: {budgetRemaining > 0 ? numeral(budgetRemaining).format('$0,0') : 0}
        </p>
        <p className="">
          Over Budget: {budgetRemaining < 0 ? numeral(budgetRemaining * -1, '').format('$0,0') : 0}
        </p>
      </div>
    );
  }

  return null;
};

const ProjectFinancials = ({ project }) => {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const category = queryParams?.category;
  const [activeBudgetIndex, setActiveBudgetIndex] = useState(0);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [activeDeliverableId, setActiveDeliverableId] = useState(null);
  const { loading, data } = useQuery(GET_DELIVERABLES_AND_EXPENSES, {
    variables: {
      projectId: project._id,
    },
  });

  const expenses = data?.projectManagement?.project?.expenses || [];
  const deliverables = data?.projectManagement?.project?.deliverables || [];

  const totalDeliverableCost = deliverables.reduce((acc, cur) => {
    return acc + cur.totalCost;
  }, 0);
  const totalExpenseCost = expenses.reduce((acc, cur) => {
    return acc + cur.amount;
  }, 0);
  const totalCost = totalDeliverableCost + (project.deductExpenses ? totalExpenseCost : 0);
  const { budget: projectBudget } = project;
  const budgetUsed = totalCost;
  const budgetRemaining = projectBudget - totalCost;

  let rowsObj = deliverables.reduce((acc, cur) => {
    if (acc[cur.milestoneName]) {
      return {
        ...acc,
        [cur.milestoneName]: [...acc[cur.milestoneName], cur],
      };
    } else {
      return {
        ...acc,
        [cur.milestoneName]: [cur],
      };
    }
  }, {});

  let categories = [];
  for (const [key, value] of Object.entries(rowsObj)) {
    const budget = value.reduce((acc, cur) => acc + cur.budget, 0);
    const totalCost = value.reduce((acc, cur) => acc + cur.totalCost, 0);
    const milestoneDeliverable = value.find(
      deliverable => deliverable.deliverableName === deliverable.milestoneName,
    );
    categories.push({
      deliverableIds: value.map(deliverable => deliverable._id),
      name: key,
      value: totalCost,
      budget: milestoneDeliverable?.budget || value.reduce((acc, cur) => acc + cur.budget, 0),
      fill: budget && totalCost > budget ? colors.red : colors.green,
    });
  }

  useEffect(() => {
    if (category && categories.length > 0) {
      const index = categories.findIndex(c => c.name === category);
      if (index >= 0) {
        setActiveCategoryIndex(index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, loading]);

  if (expenses.length > 0 && project.deductExpenses) {
    const totalExpenses = expenses.reduce((acc, cur) => acc + cur.amount, 0);
    categories.push({
      name: 'Expenses',
      value: totalExpenses,
      fill: colors.lightgray,
    });
  }

  if (loading) {
    return <TextLoader text="Loading financials" />;
  }

  if (!project.budget) {
    return (
      <Empty
        className="m-16"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={<span>No budget set for the project.</span>}
      ></Empty>
    );
  }

  const selectedCategory = categories[activeCategoryIndex]?.name;

  let itemsByCategory;
  if (selectedCategory === 'Expenses') {
    itemsByCategory = expenses.map(e => ({
      name: e.name,
      totalCost: e.amount || 0,
      budget: null,
      variance: null,
    }));
  } else {
    itemsByCategory =
      rowsObj[selectedCategory]
        ?.map(d => ({
          deliverableId: d._id,
          category: selectedCategory,
          name: d.deliverableName,
          totalCost: d.totalCost || 0,
          budget: d.budget || null,
          variance: d.budget - d.totalCost || 0,
          tasks: d.tasks,
        }))
        .filter(item => item.category !== item.name) || [];
  }

  const budgetUsedPct = budgetUsed / projectBudget;
  const budgetData = [
    { name: 'Budget Used', value: budgetUsed, fill: calcBudgetFill({ budgetUsedPct }) },
  ];

  if (budgetUsedPct < 1) {
    budgetData.push({ name: 'Budget Remaining', value: budgetRemaining, fill: colors.lightgray });
  }

  const totalDeliverableBudget = deliverables.reduce((acc, cur) => {
    return acc + cur.budget;
  }, 0);

  const totalCostAgainstBudget = `${numeral(totalCost).format('$0,0')} / ${numeral(
    categories.reduce((acc, cur) => acc + (cur.budget ? cur.budget : 0), 0),
  ).format('$0,0')}`;

  return (
    <div className="flex flex-wrap px-2 md:px-6 pt-2 md:pt-6">
      {projectBudget && (
        <Card className="w-full md:w-1/3">
          <div className="w-full text-center font-semibold">Budget</div>
          <ResponsiveContainer width="100%" height={300} className="overflow-hidden">
            <PieChart width="100%" height="100%">
              <Pie
                activeIndex={activeBudgetIndex}
                activeShape={props => renderActiveShape({ ...props, centerLabel: 'Total' })}
                dataKey="value"
                data={budgetData}
                innerRadius="40%"
                outerRadius="55%"
                onMouseEnter={(_, index) => {
                  setActiveBudgetIndex(index);
                }}
              >
                <Label value={numeral(projectBudget).format('$0,0')} position="center" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className="py-8">
            <Table className="">
              <Thead>
                <Tr className="">
                  <Th className="w-1/2"></Th>
                  <Th className="w-1/2 opacity-0">$ (%)</Th>
                </Tr>
              </Thead>
              <Tbody className="">
                <Tr className="">
                  <Td className="text-left">Project Budget Used</Td>
                  <Td className="text-right">
                    {`${numeral(budgetUsed).format('$0,0')} (${numeral(
                      budgetUsedPct < 1 ? budgetUsed / projectBudget : 1,
                    ).format('0.0%')})`}
                  </Td>
                </Tr>
                <Tr className="">
                  <Td className="text-left">Project Budget Remaining</Td>
                  <Td className="text-right">
                    {`${numeral(budgetRemaining).format('$0,0')} (${numeral(
                      budgetUsedPct < 1 ? budgetRemaining / projectBudget : 0,
                    ).format('0.0%')})`}
                  </Td>
                </Tr>
                <Tr className="">
                  <Td className="text-left font-bold">Total Project Budget</Td>
                  <Td className="font-bold text-right">{numeral(projectBudget).format('$0,0')}</Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        </Card>
      )}
      <Card
        className={classNames('w-full', {
          'md:w-1/3': projectBudget,
          'md:w-1/2': !projectBudget,
        })}
      >
        <div className="w-full text-center font-semibold">Cost</div>
        {totalCost > 0 ? (
          <ResponsiveContainer width="100%" height={300} className="overflow-hidden">
            <PieChart width="100%" height="100%">
              <Pie
                activeIndex={activeCategoryIndex}
                activeShape={props => renderActiveShape({ ...props, centerLabel: 'Total' })}
                dataKey="value"
                data={categories}
                innerRadius="40%"
                outerRadius="55%"
                fill="#82ca9d"
                onMouseUp={(_, index) => {
                  setActiveCategoryIndex(index);
                  setActiveDeliverableId(null);
                }}
                className="cursor-pointer"
              >
                <Label value={numeral(totalCost).format('$0,0')} position="center" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <div style={{ height: 300 }} className="flex flex-col justify-center">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{}}
              description={<span>No cost available yet</span>}
            ></Empty>
          </div>
        )}

        <div className="md:px-2 py-8">
          <Table className="">
            <Thead>
              <Tr className="">
                <Th className="w-2/4 text-left">Category</Th>
                <Th className="w-1/4 text-right">Cost / Budget</Th>
                <Th className="w-1/4 text-right pr-2">% of Cost</Th>
              </Tr>
            </Thead>
            <Tbody className="">
              {categories.map((c, i) => (
                <Tr
                  key={c.name}
                  className={classNames('hover:bg-gray-100 cursor-pointer', {
                    'bg-gray-200 hover:bg-gray-200': activeCategoryIndex === i,
                    'text-red-200': c.budget && c.budget < c.value,
                  })}
                  onClick={() => {
                    setActiveCategoryIndex(i);
                    setActiveDeliverableId(null);
                  }}
                >
                  <Td className="text-left text-ellipsis overflow-hidden pl-2">{c.name}</Td>
                  <Td className="text-right">{`${numeral(c.value).format('$0,0')} / ${
                    c.budget ? numeral(c.budget).format('$0,0') : 'N/A'
                  }`}</Td>
                  <Td className="text-right pr-2">{numeral(c.value / totalCost).format('0.0%')}</Td>
                </Tr>
              ))}
              <Tr
                className={classNames('hover:bg-gray-100 cursor-pointer', {
                  'bg-gray-200 hover:bg-gray-200': activeCategoryIndex === null,
                })}
                onClick={() => {
                  setActiveCategoryIndex(null);
                  setActiveDeliverableId(null);
                }}
              >
                <Td className="text-left font-bold pl-2">Total</Td>
                <Td className="pl-2 text-right">
                  <AntTooltip className="text-left font-bold" title={totalCostAgainstBudget}>
                    {totalCostAgainstBudget}
                  </AntTooltip>
                </Td>
                <Td className="text-right pr-2"></Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </Card>
      <Card
        className={classNames('w-full', {
          'md:w-1/3': projectBudget,
          'md:w-1/2': !projectBudget,
        })}
      >
        <div className="w-full text-center font-semibold">Deliverables</div>
        {itemsByCategory.reduce((acc, cur) => acc + cur.budget, 0) > 0 ? (
          <ResponsiveContainer width="100%" height={300} className="overflow-hidden">
            <BarChart width="100%" height="100%" data={itemsByCategory}>
              <XAxis dataKey="name" />
              <Tooltip content={<CustomTooltip />} />
              <ReferenceLine y={0} stroke="#000" />
              <Bar
                stackId="a"
                dataKey="totalCost"
                name="Total Cost"
                fill="#38a169"
                barSize={itemsByCategory.length < 5 ? 50 : null}
              >
                {itemsByCategory.map((entry, index) => {
                  return <Cell key={`cell-${index}`} />;
                })}
              </Bar>
              <Bar stackId="a" dataKey="variance">
                {itemsByCategory.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      stroke={entry.variance > 0 ? 'black' : null}
                      strokeWidth={entry.variance > 0 ? 1 : null}
                      strokeDasharray={entry.variance > 0 ? 3 : null}
                      fill={entry.variance > 0 ? '#f0fff4' : entry.budget ? '#e53e3e' : '#38a169'}
                    />
                  );
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div style={{ height: 300 }} className="flex flex-col justify-center">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{}}
              description={<span>No budget set on any deliverables</span>}
            ></Empty>
          </div>
        )}

        {itemsByCategory.length === 0 ? (
          <div className="py-8">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{}}
              description={<span>Select a category to see deliverables</span>}
            ></Empty>
          </div>
        ) : (
          <div className="py-8">
            <Table className="">
              <Thead>
                <Tr>
                  <Th className="text-left w-2/4">{selectedCategory}</Th>
                  <Th className="w-1/4 text-right">Cost</Th>
                  <Th className="w-1/4 text-right">Budget</Th>
                </Tr>
              </Thead>
              <Tbody className="">
                {itemsByCategory.map(c => {
                  return (
                    <Tr
                      key={c.name}
                      className={classNames('hover:bg-gray-100 cursor-pointer rounded-md', {
                        'bg-gray-200 hover:bg-gray-200': activeDeliverableId === c.deliverableId,
                        'text-red-200':
                          c.budget && selectedCategory !== 'Expenses' && c.budget < c.totalCost,
                      })}
                      onClick={() => {
                        if (activeDeliverableId === c.deliverableId) {
                          setActiveDeliverableId(null);
                        } else {
                          setActiveDeliverableId(c.deliverableId);
                        }
                      }}
                    >
                      <Td className="pl-2 text-left">{c.name}</Td>
                      <Td className="text-right">{numeral(c.totalCost).format('$0,0')}</Td>
                      <Td className="pr-2 text-right">
                        {c.budget ? numeral(c.budget).format('$0,0') : 'N/A'}
                      </Td>
                    </Tr>
                  );
                })}
                <Tr className="">
                  <Td className="pl-2 text-left font-bold">Total</Td>
                  <Td className="font-bold text-right">
                    {numeral(itemsByCategory.reduce((acc, cur) => acc + cur.totalCost, 0)).format(
                      '$0,0',
                    )}
                  </Td>
                  <Td className="pr-2 font-bold text-right">
                    {numeral(itemsByCategory.reduce((acc, cur) => acc + cur.budget, 0)).format(
                      '$0,0',
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        )}
      </Card>
      <div className="w-full pb-6">
        {selectedCategory !== 'Expenses' && (
          <ProjectFinancialsDetailsTable
            projectId={project._id}
            itemsByCategory={itemsByCategory}
            category={categories[activeCategoryIndex]}
            activeDeliverableId={activeDeliverableId}
          />
        )}
        {totalDeliverableBudget > projectBudget && (
          <Card floating className="my-6">
            <Alert
              message={`Project budget is ${numeral(projectBudget).format(
                '$0,0',
              )} while total deliverable budget adds up to ${numeral(totalDeliverableBudget).format(
                '$0,0.',
              )}. Make sure that the total budget specified for deliverables is equal to or less than project budget.`}
              type="warning"
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default ProjectFinancials;
