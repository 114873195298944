import { useState, useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { groupBy, get, flatten, keyBy, values } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import DateRangePicker from '../../common/components/DateRangePicker';
import client from '../../../api/graphql/client';
import Card from '../../common/components/Card';
import Profile from '../../common/components/Profile';
import Button from '../../common/components/Button';
import TextLoader from '../../common/components/TextLoader';
import ProjectFinancialsDetailsGrid from './ProjectFinancialsDetailsGrid';

export const GET_PROJECT_FINANCIAL_RAW = gql`
  query getTimesheetWeeklySnapshot(
    $taskIds: [String]
    $projectId: String
    $startDate: String
    $endDate: String
  ) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        roles {
          _id
          name
          rate
        }
        resources {
          _id
          email
          role
          startDate
          endDate
          user {
            _id
            imageUrl
          }
        }
        timesheetSnapshots(taskIds: $taskIds, startDate: $startDate, endDate: $endDate)
      }
    }
  }
`;

const fetchProjectWithFinancials = async ({ taskIds, projectId, startDate, endDate }) => {
  let currentStart = moment(startDate);
  let end = moment(endDate);
  let allFinancials = [];
  let project;

  while (currentStart.isBefore(end)) {
    let currentEnd = currentStart.clone().endOf('month');
    if (currentEnd.isAfter(end)) {
      currentEnd = end.clone();
    }
    const { data } = await client.query({
      query: GET_PROJECT_FINANCIAL_RAW,
      variables: {
        taskIds,
        projectId,
        startDate: currentStart.format('YYYYMMDD'),
        endDate: currentEnd.format('YYYYMMDD'),
      },
    });

    project = data.projectManagement.project;
    allFinancials.push(...(data.projectManagement.project.timesheetSnapshots || []));
    currentStart = currentEnd.add(1, 'day');
  }

  const hashMap = keyBy(allFinancials, '_id');
  const finalFetchedData = values(hashMap);

  project.timesheetSnapshots = finalFetchedData;

  return project;
};

export const sumLineItemHours = li => {
  const mon = get(li, 'monTask.hours') || 0;
  const tue = get(li, 'tueTask.hours') || 0;
  const wed = get(li, 'wedTask.hours') || 0;
  const thu = get(li, 'thuTask.hours') || 0;
  const fri = get(li, 'friTask.hours') || 0;
  const sat = get(li, 'satTask.hours') || 0;
  const sun = get(li, 'sunTask.hours') || 0;
  return mon + tue + wed + thu + fri + sat + sun;
};

export const calculateCost = ({
  email,
  totalHours,
  resources,
  roles,
  userRole,
  deliverableRate,
}) => {
  const resource = resources?.find(r => r.email === email && r.startDate);
  let role = roles?.find(r => r.name === resource?.role);
  let value;

  if (deliverableRate) {
    value = deliverableRate * totalHours;
  }

  if (!role) {
    // if user was not specifically added to the project as a resource with a role, then use the global role they are assigned to
    role = roles?.find(r => r.name === userRole);
  }
  if (role) {
    const rate = role.rate;
    value = totalHours * rate;
  }

  if (value) {
    return parseFloat(value.toFixed(2));
  }

  return null;
};

const createGridData = (timesheets, resources, roles) =>
  flatten(
    timesheets.map(ts =>
      ts.lineItems.map(li => {
        const deliverableRate = li?.deliverable?.rate;
        const user = ts.user;
        const email = get(ts, 'user.emails[0].address');
        const userRole = get(user, 'role');
        const resource = resources?.find(r => r.email === email && r.startDate);
        const resourceRole = resource?.role;

        let bu = `${get(li, 'project.accountingCode') || ''}-${
          get(li, 'project.accountingCodeSuffix') || ''
        }`;
        if (get(li, 'deliverable.accountingCode') && get(li, 'deliverable.accountingCodeSuffix')) {
          bu = `${get(li, 'deliverable.accountingCode') || ''}-${
            get(li, 'deliverable.accountingCodeSuffix') || ''
          }`;
        }
        let days = ['monTask', 'tueTask', 'wedTask', 'thuTask', 'friTask', 'satTask', 'sunTask'];
        let finalData = [];
        for (let i = 0; i < days.length; i++) {
          let taskDate = get(li, days[i] + '.date');
          let taskHour = get(li, days[i] + '.hours');
          let taskComment = get(li, days[i] + '.comment');
          let temp = {
            deliverableId: get(li, 'deliverable._id'),
            timesheetId: get(ts, '_id'),
            status: get(ts, 'status'),
            endDate: moment(get(ts, 'endDate')).format('MM-DD-YYYY'),
            firstName: get(ts, 'user.firstName'),
            lastName: get(ts, 'user.lastName'),
            role: resourceRole || userRole,
            payrollId: get(ts, 'user.payrollId'),
            email: get(ts, 'user.emails[0].address'),
            clientName: get(li, 'project.client.shortName'),
            project: get(li, 'project.name'),
            bu: bu,
            milestone: get(li, 'deliverable.milestoneName'),
            deliverable: get(li, 'deliverable.deliverableName'),
            taskDate: moment(taskDate, 'YYYYMMDD').format('MM/DD/YYYY'),
            hours: taskHour,
            comment: taskComment,
            totalCost: calculateCost({
              email: get(ts, 'user.emails[0].address'),
              totalHours: taskHour,
              resources,
              roles,
              userRole: get(ts, 'user.role'),
              deliverableRate,
            }),
          };
          if (taskHour > 0) {
            finalData.push(temp);
          }
        }
        return finalData;
      }),
    ),
  );

let exportFunc;

const handleExportClick = () => exportFunc();

const onExportReady = ({ getExportFunc }) => {
  exportFunc = getExportFunc();
};

const ProjectFinancialsDetailsTable = ({
  user,
  projectId,
  itemsByCategory,
  category,
  activeDeliverableId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [dateRange, setDateRange] = useState([moment('20240301'), moment()]);
  const startDate = dateRange[0].format('YYYYMMDD');
  const endDate = dateRange[1].format('YYYYMMDD');
  const [selectedEmail, setSelectedEmail] = useState(null);

  const taskIds = useMemo(() => {
    return itemsByCategory.reduce((acc, cur) => {
      return [...acc, ...cur.tasks.map(t => t._id)];
    }, []);
  }, [itemsByCategory]); // Only recompute if itemsByCategory changes

  useEffect(() => {
    setLoading(true);
    fetchProjectWithFinancials({ taskIds, projectId, startDate, endDate })
      .then(data => {
        setProject(data);
        setLoading(false);
      })
      .catch(console.error); // Handle errors appropriately in production
  }, [taskIds, projectId, startDate, endDate]);

  if (loading) {
    return <TextLoader text="Loading details" />;
  }

  const roles = get(project, 'roles');
  const resources = get(project, 'resources');
  const { timesheetSnapshots } = project;

  let rowData = createGridData(timesheetSnapshots, resources, roles).flat();

  rowData = rowData
    .filter(r => category ? category.deliverableIds.includes(r.deliverableId) : true)
    .filter(r => r.status === 'Approved');

  if (activeDeliverableId) {
    rowData = rowData.filter(r => r.deliverableId === activeDeliverableId);
  }

  const rowDataByUserObj = groupBy(rowData, 'email');
  const rowDataByUserArr = Object.keys(rowDataByUserObj).map(key => ({
    key,
    value: rowDataByUserObj[key],
  }));
  rowData = rowData.filter(r => (selectedEmail ? r.email === selectedEmail : true));

  return (
    <div>
      {rowDataByUserArr.length > 0 && (
        <Card title="Contributors" floating padded={false}>
          <div className="flex flex-wrap gap-4">
            {rowDataByUserArr.map(({ key, value }) => {
              const resource = resources.find(r => r.email === key);
              return (
                <div
                  key={key}
                  onClick={() => setSelectedEmail(selectedEmail === key ? null : key)}
                  className={classNames('hover:bg-gray-100 cursor-pointer p-4 rounded-md', {
                    'bg-gray-200 hover:bg-gray-200': selectedEmail === key,
                  })}
                >
                  <Profile
                    imageUrl={resource?.user?.imageUrl}
                    firstName={value[0].firstName}
                    lastName={value[0].lastName}
                    email={key}
                    firstLine={`${numeral(value.reduce((acc, cur) => acc + cur.hours, 0)).format(
                      '0,0.0',
                    )} hours`}
                    secondLine={`${numeral(
                      value.reduce((acc, cur) => acc + cur.totalCost, 0),
                    ).format('$0,0.00')}`}
                  />
                </div>
              );
            })}
          </div>
        </Card>
      )}
      <Card
        border
        title="Approved Hours"
        floating
        padded={false}
        actionComponent={
          <div className="flex">
            <DateRangePicker
              loading={loading}
              handleDateChange={range => {
                setDateRange(range);
              }}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
            />
            <div className="pl-2">
              <Button disabled={loading} onClick={handleExportClick}>
                Export
              </Button>
            </div>
          </div>
        }
      >
        <ProjectFinancialsDetailsGrid
          user={user}
          rowData={rowData}
          startDate={startDate}
          endDate={endDate}
          onExportReady={onExportReady}
          {...rest}
        />
      </Card>
    </div>
  );
};

export default ProjectFinancialsDetailsTable;
